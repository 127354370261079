.aboutContainer {
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-image: linear-gradient(0deg, rgba(20, 26, 33, 0.8), rgba(20, 26, 33, 0.8)),
    url(../assets/images/contact.webp);
  height: 70vh;
  > * {
    line-height: 0.5 !important;
  }
}

.test {
  display: flex;
  flex-direction: column;
  /* background-color: bisque !important; */
  /* width: 20rem;
   */
  width: 485px;
  /* line-height: 1; */

  position: relative;
  left: 20%;
  top: 40%;

  > * {
    text-align: left;
    margin: 10px;
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  .test {
    left: 5%;
  }
}

.contactContainer {
  position: relative;
  background: linear-gradient(rgba(22, 28, 36, 0.8), rgba(22, 28, 36, 0.8))
      center center / cover no-repeat,
    url(../assets/contact.jpg);
  background-position: center center;
  overflow: hidden;
}

.heading {
  font-weight: 800 !important;
  line-height: 1.25;
  /* font-size: 2.5rem; */
  font-family: "Public Sans", sans-serif;
  overflow: hidden;
  display: inline-flex;

  font-size: 4rem !important;
}

.green {
  color: rgb(0, 167, 111);
}

.white {
  color: white;
}

.success-page {
  text-align: center;
  padding: 20px;
}

.schedule-meeting-btn {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.schedule-meeting-btn:hover {
  background-color: #3e8e41;
}

.animatedButton {
  animation: animate__bounce 1s infinite 2s;
  /* 
  animation-name: animate__bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
  */
}

@keyframes backgroundColorChange {
  0% {
    background-color: red;
  }
  25% {
    background-color: yellow;
  }
  50% {
    background-color: green;
  }
  75% {
    background-color: blue;
  }
  100% {
    background-color: red;
  }
}

.bgColorAnimation {
  animation: bounce 4s infinite;
}
