.aboutContainer {
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-image: url(../../assets/svg/aboutBackground.svg),
    url(../../assets/images/about.jpg);
  height: 70vh;
  > * {
    line-height: 0.5 !important;
  }
}

.test {
  display: flex;
  flex-direction: column;
  /* background-color: bisque !important; */
  // width: 20rem;
  width: 480px;
  /* line-height: 1; */



  position: relative;
  left: 20%;
  top: 40%;

  > * {
    text-align: left;
    margin: 10px;
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  .test {
    left: 5%;
  }
}

.roundImg {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  margin: auto;
}

.animatedButton {
  animation: animate__bounce 1s infinite 2s;
  /* 
  animation-name: animate__bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
  */
}
