.navItem {
  display: inline-block;

  /* &:not(:last-child) {
    margin-right: 1.5rem;
  } */
  transition: all 0.2s;

  /* &:hover { */
  /* transform: translateY(-1rem); */
  /* transform: skewY(5deg) scale(1.3); */
  /* color: $color-primary;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.4); */
  /* cursor: pointer; */
  /* color: red; */
  /* }  */
}

/* .iconBtn {
  transition: all 1s;
  cursor: pointer;
  &:hover {
    transform: translateY(-1rem);
  }
} */
.myGrid {
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: repeat(2, max-content);
}
