.circular-progress {
  display: block;
  margin: auto;
}

.progress-bar-bg {
  fill: none;
  stroke: #f0f0f0;
  stroke-width: 10;
}

.progress-bar {
  fill: none;
  stroke: #4caf50;
  transition: stroke-dashoffset 0.3s ease;
  stroke-width: 10;
  stroke-linecap: round;
}

.progress-text {
  font-family: Arial, sans-serif;
  font-size: 2em;
  fill: #666;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  fill: #555;
}
