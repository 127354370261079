.fixedHeader {
  box-shadow: none !important;
  background: transparent !important;
  position: fixed;
}

.stickyHeader {
  background: #333333 !important;
  position: sticky;
  animation: fadeInDown 0.5s ease-in-out 0s 1 normal none running;
  color: black;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
