.animateRight {
  animation: slideRightAnimation 1s;
}

.animateLeft {
  animation: slideLeftAnimation 1s;
}

.fade {
  animation: fadeAnimation 2s;
}

.invisible{
  opacity: 0;
}


.fadeInDown{
  animation: animate__fadeInDown .2s;
}

@keyframes slideRightAnimation {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 100;
  }
}

@keyframes slideLeftAnimation {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 100;
  }
}

@keyframes fadeAnimation {
  0% {
    /* transform: translateX(50%); */
    opacity: 0;
  }
  100% {
    /* transform: translateX(0); */
    opacity: 100;
  }
}
