$header-color: #18181b;
$header-height: 100vh;
$navbar-height: 85px;

.header {
  height: $header-height;
  width: auto;
  background: linear-gradient(rgba(24, 24, 27, 0.85), rgba(24, 24, 27, 0.85)),
    url(../../assets/images/header-1.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    // background-color: red;
    margin-top: calc($navbar-height + 100px);
    gap: 10px;
    @media screen and (max-width: 700px) {
      margin-top: calc($navbar-height + 5px);
    }
  }

  &__image {
    animation: border-transform 10s linear infinite alternate forwards;
    width: 100%;
  }
}

@keyframes border-transform {
  0%,
  100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }

  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}
