.heading {
  font-size: 50px !important;
  font-weight: 600 !important;
  text-transform: none;
  line-height: 1.1em;
  font-family: "Crimson Pro", Sans-serif;
}

.subHeading {
  font-family: "Poppins", Sans-serif;
  font-size: 24px !important ;
  font-weight: 400 !important;
}

.separator {
  height: 2px;
  background: blue;
  width: 100%;
  margin: 10px;
}

.number {
  font-family: "Poppins", Sans-serif;
  font-size: 50px !important;
  font-weight: 600 !important   ;
}
