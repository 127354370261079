$header-height: 60vh;

.header {
  height: $header-height;
  width: auto;
  // background: linear-gradient(rgba(24, 24, 27, 0.85), rgba(24, 24, 27, 0.85)),
  // url(https://t3.ftcdn.net/jpg/00/56/71/00/360_F_56710032_HM18W4DqNYH3pHTzQrzE0LIKCswvS1Va.jpg);
    // url(https://media.istockphoto.com/id/578108630/photo/push-pins-on-a-road-map.jpg?s=2048x2048&w=is&k=20&c=eHZzfPv0omJ38HNcsnXYeRt-KwNKvo_2wdine8N2Nhs=);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.descGrid {
  display: grid;
  width: 80%;
  // background: #578853;
  margin: 30px auto;
  grid-template-columns: 60% 30%;
  grid-template-rows: min-content 1fr;
  grid-column-gap: 20px;
}

@media screen and (max-width: 1400px) {
  .descGrid {
    width: 80%;
  }
}

@media screen and (max-width: 900px) {
  .descGrid {
    display: flex;
    flex-wrap: wrap;
  }
}
