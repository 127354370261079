.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html {
  font-size: 16px; /* 1rem = 16px */
  scroll-behavior: smooth;
}

/* Media query for smaller screens */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Global Styles */
.headingPrimary {
  font-size: 3rem !important;
  text-align: center;
  font-weight: 700 !important;
  /* background-image: linear-gradient(to right, #e6004d, #2600b3, #b30077); */
  color: transparent;
  background-clip: text;
  margin: 25px 0px !important;
}

@media screen and (max-width: 600px) {
  .headingPrimary {
    font-size: 3rem !important;
    text-align: center;
  }
}

.subHeading {
  line-height: 1.5;
  font-size: 1rem;
  font-family: "__Public_Sans_f258fb", "__Public_Sans_Fallback_f258fb",
    Helvetica, Arial, sans-serif;
  color: #637381;
  font-weight: 400;
  max-width: 360px;
}

.invisible {
  opacity: 0;
}

.separator {
  height: 2px;
  background: resources;
  width: 100%;
  margin: 10px;
}

.h4 {
  color: #fff;
  font-size: 1.5rem !important;
  margin: 24px 0px 0px;
  line-height: 1.5;
  font-family: Public Sans, sans-serif;
  font-weight: 600;
}

#powered_by_prefooter {
  display: none;
}

/* ------------------Typography style-------------------------------- */

h1,
h2,
h3 {
  text-align: left;
}
p {
  font-size: 14px; /* Default for smallest screens */
  line-height: 1.6; /* Adjust line height for readability */
  margin-bottom: 1rem; /* Space between paragraphs */
  text-align: justify; /* Justify text for better readability */
}

/* Small screens (min-width: 600px) */
@media (min-width: 600px) {
  p {
    font-size: 16px; /* Increase font size for small screens */
  }
  h1 {
    font-size: 32px; /* Increase font size for small screens */
  }
  h2 {
    font-size: 28px; /* Increase font size for small screens */
  }
  h3 {
    font-size: 24px; /* Increase font size for small screens */
  }
}

/* Medium screens (min-width: 900px) */
@media (min-width: 900px) {
  p {
    font-size: 18px; /* Increase font size for medium screens */
  }
  h1 {
    font-size: 36px; /* Increase font size for medium screens */
  }
  h2 {
    font-size: 32px; /* Increase font size for medium screens */
  }
  h3 {
    font-size: 28px; /* Increase font size for medium screens */
  }
}

/* Large screens (min-width: 1200px) */
@media (min-width: 1200px) {
  p {
    font-size: 20px; /* Increase font size for large screens */
  }
  h1 {
    font-size: 40px; /* Increase font size for large screens */
  }
  h2 {
    font-size: 36px; /* Increase font size for large screens */
  }
  h3 {
    font-size: 32px; /* Increase font size for large screens */
  }
}

/* Extra Large screens (min-width: 1536px) */
@media (min-width: 1536px) {
  p {
    font-size: 22px; /* Increase font size for extra large screens */
  }
  h1 {
    font-size: 48px; /* Increase font size for extra large screens */
  }
  h2 {
    font-size: 42px; /* Increase font size for extra large screens */
  }
  h3 {
    font-size: 36px; /* Increase font size for extra large screens */
  }
}

/* ------------------Google translator styles------------------------ */
#google_element {
  position: absolute;
  right: 20px; /* Increased right margin for better spacing */
  top: 10px; /* Added top margin for better positioning */
}

@media screen and (max-width: 480px) {
  #google_element {
    right: 5px; /* Increased right margin for better spacing */
    top: 0px;
  }
}

#google_translate_element {
  margin: 20px;
  padding: 10px;
  border: 1px solid #ddd; /* Changed border color to a lighter gray */
  background-color: #fff; /* Changed background color to white */
  border-radius: 10px; /* Increased border radius for a softer look */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Added box shadow for depth */
}

/* Customize the select box */
.goog-te-combo {
  padding: 10px; /* Increased padding for better spacing */
  border-radius: 10px; /* Increased border radius for a softer look */
  border: 1px solid #ccc; /* Changed border color to a lighter gray */
  background-color: transparent; /* Changed background color to white */
  color: #aaa; /* Changed text color to a darker gray */
  font-size: 16px; /* Increased font size for better readability */
  font-weight: 500; /* Added font weight for a bolder look */
  cursor: pointer; /* Added cursor pointer for a better user experience */
}

.goog-te-combo > * {
  /* Example style: red text color */
  font-size: 18px; /* Example style: 18px font size */
  padding: 5px; /* Example style: 5px padding */
  background-color: #f0f0f0; /* Example style: light grey background */
  margin-bottom: 10px;
}

/* Customize the button */
.goog-te-combo:hover {
  /*border-color: #aaa; /* Changed border color on hover to a darker gray */
  /*background-color: #f7f7f7; /* Changed background color on hover to a lighter gray */
  background-color: transparent;
  /* color: white; */
}

option {
  padding: 100px !important;
}

.goog-te-combo:focus {
  border-color: #666; /* Changed border color on focus to a darker gray */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Added box shadow on focus for depth */
}

/* Hide Google branding */
.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none;
}


.tidycal-embed .nice-dates-day:before {
  background-color: goldenrod !important;
}